<template>
  <div class="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
        <div class="flex a-center"
             slot="extra">
          <Icon custom="i-icon icon-jingshi"
                size="24"
                color="#FF9B77"></Icon>
          当前班次完成率计算方式：路径完成率：{{path}}%;
          <Button type="text"
                  class="m-l-5"
                  @click.stop="onClickComplete">设置完成度计算</Button>
        </div>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <template v-if="currentTabIndex!==3">
              <Select v-model="selectType"
                      @on-change="onChangeSelectType"
                      placeholder="查询分类"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in selectTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
            </template>
            <template v-if="currentTabIndex===0">
              <MyTreeSelect :value="departName"
                            placeholder="所属机构"
                            clearable
                            :data="departmentTreeArr"
                            search
                            class="m-r-10"
                            style="width:160px"
                            @onSelectChange="onSelectChangeDepart">
              </MyTreeSelect>
              <MyTreeSelect :value="gridName"
                            placeholder="网格名称"
                            clearable
                            :data="gridNameTreeArr"
                            search
                            checked
                            class="m-r-10"
                            style="width:160px"
                            @onCheckChange="onChangeGridName">
              </MyTreeSelect>
              <Input v-model="planName"
                     placeholder="计划名称"
                     maxlength="30"
                     clearable
                     v-stringLimit
                     class="m-r-10"
                     style="width: 160px" />
            </template>
            <DatePicker :value="date"
                        type="daterange"
                        :start-date="new Date()"
                        placeholder="请选择时间范围"
                        @on-change="onChangeDate"
                        style="width:260px"
                        v-if="selectType===1"></DatePicker>
            <DatePicker :value="dateMonth"
                        type="month"
                        placeholder="选择月份"
                        @on-change="onChangeDate"
                        style="width:160px"
                        v-if="selectType===2"></DatePicker>
          </div>
          <div class="flex">
            <template v-if="currentTabIndex!==3">
              <template v-if="selectType===1">
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/roadClean-work/query'])"
                        :ghost="buttonIndex == 0 ? false : true"
                        @click.stop="onClickToday">今天</Button>
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/roadClean-work/query'])"
                        :ghost="buttonIndex == 1 ? false : true"
                        @click.stop="onClickYesterday">昨天</Button>
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/roadClean-work/query'])"
                        :ghost="buttonIndex == 2 ? false : true"
                        @click.stop="onClickNearlyThreeDay">近三天</Button>
              </template>
              <template v-if="selectType===2">
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/roadClean-work/query'])"
                        :ghost="buttonIndex == 3 ? false : true"
                        @click.stop="onClickCurrentMonth">本月</Button>
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/roadClean-work/query'])"
                        :ghost="buttonIndex == 4 ? false : true"
                        @click.stop="onClickLastMonth">上月</Button>
              </template>
            </template>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/roadClean-work/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/roadClean-work/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <template v-if="currentTabIndex===0">
        <div>
        </div>
        <div class="flex a-center j-between">
          <Tabs v-model="currentGridWorkTabIndex"
                class="tabs"
                key="child1"
                style="flex:1;">
            <TabPane v-for="item in gridWorkTabArr"
                     :key="item.name"
                     :label="item.name"
                     :disabled="currentGridWorkTabIndex==0"></TabPane>
          </Tabs>
        </div>
      </template>
      <div class="table">
        <Table ref="table"
               :width="tableWidth"
               :height="tableHeight"
               :columns="getColumns"
               :data="data"
               border
               stripe
               :loading="tableLoading"
               @on-selection-change="onChangeSelect"
               @on-sort-change="onChangeSort">
          <template slot-scope="{ row }"
                    slot="operation">
            <Button @click.stop=""
                    type="text">处理</Button>
          </template>
        </Table>
        <Page class="m-t-10 m-l-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
      </div>
    </div>

    <!-- 完成度设置 -->
    <GridFinishDegreeModal v-model="gridCompleteVisible"
                           :type.sync="gridCompleteType"
                           @onClickConfirm="getCompleteness"></GridFinishDegreeModal>

    <!-- 查看地址 -->
    <PositionDetailModal v-model="positionDetailVisible"
                         :address="positionAddress"
                         :lng="positionLng"
                         :lat="positionLat"></PositionDetailModal>

    <!-- 查看网格 -->
    <GridDetailSmallModal v-model="gridDetailSmallVisible"
                          :dataId.sync="gridDetailSmallId"></GridDetailSmallModal>
  </div>
</template>

<script>
import { getCurrentMonth, getToday, getYesterday, getNearlyThreeDay, getLastMonth, formatSecond } from '@/utils/dateRangUtil'
import GridFinishDegreeModal from '@/components/product/admin/modal/work/GridFinishDegreeModal'
import { mapActions, mapGetters } from 'vuex';
import MyTreeSelect from '@/components/common/MyTreeSelect';
import PositionDetailModal from '@/components/product/main/template/PositionDetailModal';
import GridDetailSmallModal from '@/components/product/admin/modal/work/GridDetailSmallModal'
import Echarts from '@/components/common/Echarts';
import ExportTemplate from '@/components/common/ExportTemplate'
import { checkPermi } from '@/utils/permission'
export default {
  components: {
    GridFinishDegreeModal,
    MyTreeSelect,
    PositionDetailModal,
    GridDetailSmallModal,
    Echarts,
    ExportTemplate
  },
  data () {
    return {
      currentTabIndex: 0,
      buttonIndex: null,
      tabArr: [
        {
          name: '网格作业情况'
        },
        // {
        //   name: '操作记录'
        // },
      ],
      selectType: 1,
      selectTypeArr: [
        {
          id: 1,
          name: '按天查询'
        },
        {
          id: 2,
          name: '按月查询'
        },
      ],
      carType: '',
      carTypeName: '',
      // carTypeArr: [],
      carNumber: '',
      carNumberId: '',
      workLevel: '',
      workLevelName: '',
      // workLevelArr: [],
      gridName: '',
      gridId: '',
      departId: '',
      departName: '',
      plan: [],
      planName: '',
      planId: [],
      planNameArr: [],
      violateType: '',
      violateTypeArr: [],
      operator: '',
      date: [],
      dateMonth: '',
      status: 0,
      currentGridWorkTabIndex: 0,
      gridWorkTabArr: [
        {
          name: '作业汇总'
        },
        {
          name: '作业明细'
        }
      ],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableWidth: 0,
      tableHeight: 0,
      tableLoading: false,
      columnsGridWorkSummary: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '计划名称',
          minWidth: 60,
          key: 'planName',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickGridWorkName(row)
                }
              }
            }, row.planName);
          }
        },
        {
          title: '网格名称',
          minWidth: 60,
          key: 'gridName',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickGridWorkDetailGrid(row)
                }
              }
            }, row.gridName);
          }
        },
        {
          title: '所属机构',
          minWidth: 60,
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '计划天数',
          minWidth: 60,
          key: 'planDays',
          tooltip: true,
          align: 'center'
        },
        {
          title: '完成天数',
          minWidth: 60,
          key: 'overDays',
          tooltip: true,
          align: 'center'
        },
        {
          title: '未完成天数',
          minWidth: 60,
          key: 'unOverDays',
          tooltip: true,
          align: 'center'
        },
        {
          title: '完成人数',
          minWidth: 60,
          key: 'overPersons',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.completeDays == 0) {
              return h('span', {}, row.completeDays);
            } else {
              return h('span', {
                style: {
                  color: '#25bb96',
                  cursor: 'pointer'
                },
                on: {
                  click: () => {
                    this.onClickGridWorkFinish(row)
                  }
                }
              }, row.overPersons);
            }
          }
        },
        {
          title: '报警次数',
          minWidth: 60,
          key: 'alarmCounts',
          tooltip: true,
          align: 'center'
        },
        {
          title: '报警次数',
          minWidth: 60,
          key: 'violationCounts',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsGridWorkDetail: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '计划名称',
          minWidth: 60,
          key: 'planName',
          tooltip: true,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '网格名称',
          minWidth: 60,
          key: 'gridName',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickGridWorkDetailGrid(row)
                }
              }
            }, row.gridName);
          }
        },
        // {
        //   title: '实施日期',
        //   minWidth: 60,
        //   key: 'implementationDate',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '完成人',
          minWidth: 60,
          key: 'overPersonName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业时长',
          width: 100,
          key: 'workTimeLength',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业开始时间',
          minWidth: 100,
          key: 'startDate',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业结束时间',
          minWidth: 100,
          key: 'endDate',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属部门',
          minWidth: 60,
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '报警次数',
          minWidth: 60,
          key: 'alarmCounts',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违规次数',
          minWidth: 60,
          key: 'violationCounts',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '点火次数',
        //   minWidth: 60,
        //   key: 'ignitionNumber',
        //   tooltip: true,
        //   align: 'center'
        // },
      ],
      data: [],
      selectArr: [],
      options: {
        legend: {},
        tooltip: {},
        xAxis: {
          type: 'category'
        },
        yAxis: {
          type: 'value'
        },
        dataset: {
          source: []
        },
        series: [
          {
            type: 'bar'
          }
        ]
      },
      violateChartData: [],
      // 完成度
      gridCompleteVisible: false,
      gridCompleteType: null,
      mileage: '',  // 里程
      path: '',      // 路径
      motion: '',      // 运动时长
      // 查看地址
      positionDetailVisible: false,
      positionAddress: '',
      positionLng: 0,
      positionLat: 0,
      // 查看网格
      gridDetailSmallVisible: false,
      gridDetailSmallId: '',
    };
  },
  watch: {
    currentTabIndex () {
      this.resetSearchData()
      this.onClickCurrentMonth()
      this.currentGridWorkTabIndex = 0
    },
    currentGridWorkTabIndex (newVal, old) {
      if (newVal == 0) {
        this.gridName = ''
        this.gridId = ''
      }
      this.onClickSearch()
    },
    status () {
      this.onClickSearch()
    },
  },
  computed: {
    ...mapGetters({
      'carTreeArr': 'getCarTreeList',
      'gridNameTreeArr': 'getGridTreeList',
      'departmentTreeArr': 'getDepartmentTreeList',

    }),
    carTypeArr () {
      let arr = [{
        id: 0,
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getCarTypeAllList)
    },
    workLevelArr () {
      let arr = [{
        levelId: 0,
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getRoadAttributeAllList)
    },
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentGridWorkTabIndex) {
            case 0:
              columns = this.columnsGridWorkSummary
              break;
            case 1:
              columns = this.columnsGridWorkDetail
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return columns
    },
    getExportPath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentGridWorkTabIndex) {
            case 0:
              path = this.$http.exportCarWorkJxzySummaryList
              break;
            case 1:
              path = this.$http.exportCarWorkJxzyDetailList
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return path
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateCarTypeAllList',
      'updateCarTreeList',
      'updateRoadAttributeAllList',
      'updateGridTreeList',
      'updateDepartmentTreeList'
    ]),
    init () {
      this.tableWidth = window.innerWidth - 240
      this.tableHeight = window.innerHeight - 370
      window.onresize = () => {
        this.tableWidth = window.innerWidth - 240
        this.tableHeight = window.innerHeight - 370
      }
      this.updateDepartmentTreeList()
      this.updateCarTypeAllList()
      this.updateCarTreeList()
      this.updateRoadAttributeAllList()
      this.updateGridTreeList()
      this.getCompleteness()
      this.onClickCurrentMonth()
      this.getPlanList()
      this.getViolateTypeList()
    },
    // 获取完成度
    getCompleteness () {
      this.$store.dispatch('getTimeCompleteDetail', 2).then(res => {
        this.mileage = res.kmOkThreshold
        this.path = res.routeOkThreshold
        this.motion = res.motionOkThreshold
      })
    },
    // 设置完成度
    onClickComplete () {
      this.gridCompleteVisible = true
      this.gridCompleteType = 2
    },
    // 获取计划列表
    getPlanList () {
      let params = {
        page: 1,
        size: 99999,
      }
      this.$http.getMechanicalPlanList(params).then(res => {
        this.planNameArr = res.result.plans
      })
    },
    // 获取违规类型列表
    getViolateTypeList () {
      this.$http.getWorkRuleItemList({ type: 3 }).then(res => {
        if (res.code === 200) {
          let arr = [{
            id: 0,
            name: '全部'
          }]
          this.violateTypeArr = arr.concat(res.result.map(item => {
            item.id = item.customName
            item.name = item.customName
            return item
          }))
        }
      })
    },
    // 今天
    onClickToday () {
      this.date = [getToday(), getToday()]
      this.onClickSearch()
      this.buttonIndex = 0
    },
    // 昨天
    onClickYesterday () {
      this.date = [getYesterday(), getYesterday()]
      this.onClickSearch()
      this.buttonIndex = 1
    },
    // 近三天
    onClickNearlyThreeDay () {
      this.date = getNearlyThreeDay()
      this.onClickSearch()
      this.buttonIndex = 2
    },
    // 本月
    onClickCurrentMonth () {
      this.date = getCurrentMonth()
      let time = getCurrentMonth()[0].split('-')
      this.dateMonth = time[0] + '-' + time[1]
      this.onClickSearch()
      this.buttonIndex = 3
    },
    // 上月
    onClickLastMonth () {
      this.date = getLastMonth()
      let time = getLastMonth()[0].split('-')
      this.dateMonth = time[0] + '-' + time[1]
      this.onClickSearch()
      this.buttonIndex = 4
    },
    // 查询分类回调
    onChangeSelectType (value) {
      if (value == 1) {
        this.date = getCurrentMonth()
      } else {
        let time = getCurrentMonth()[0].split('-')
        let day = new Date(time[0], time[1], 0).getDate()
        this.dateMonth = time[0] + '-' + time[1]
        this.date[0] = time[0] + '-' + time[1] + '-01'
        this.date[1] = time[0] + '-' + time[1] + '-' + day
      }
    },
    // 选择所属机构
    onSelectChangeDepart (data) {
      this.departId = data.id;
      this.departName = data.name;
    },
    // 选择网格
    onChangeGridName (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type != 'depart') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.gridName = valueArr.join()
      this.gridId = idArr.join()
    },
    // 计划名称回调
    onChangePlanName (data) {
      let idArr = []
      let valueArr = []
      data.forEach(item => {
        idArr.push(item.value)
        valueArr.push(item.label)
      });
      this.planName = valueArr
      this.planId = idArr
    },
    // 点击网格作业情况表格中网格名称
    onClickGridWorkName (row) {
      this.gridId = row.gridId
      this.gridName = row.gridName
      this.currentGridWorkTabIndex = 1
    },
    // 点击网格作业详细表格中网格名称
    onClickGridWorkDetailGrid (row) {
      this.gridDetailSmallVisible = true
      this.gridDetailSmallId = row.gridId
    },
    // 点击网格作业情况表格中车辆完成天数
    onClickGridWorkFinish (row) {
      this.status = 1
      this.gridId = row.gridId
      this.overPersons = row.overPersons
      this.currentGridWorkTabIndex = 1
    },
    // 点击网格作业情况表格中车辆未完成天数
    onClickGridWorkUnFinish (row) {
      this.status = 2
      this.gridId = row.gridId
      this.gridName = row.gridName
      this.currentGridWorkTabIndex = 1
    },

    // 明细单选框回调
    onChangeRadio () {
      this.onClickSearch()
    },
    // 点击表格中的查看位置
    onClickAddress (row) {
      this.positionAddress = row.address
      this.positionLng = row.lng
      this.positionLat = row.lat
      this.positionDetailVisible = true
    },
    // 查询
    onClickSearch () {
      if (this.currentTabIndex == 2) {
        this.getEcharts()
      }
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickCurrentMonth()
    },
    // 重置搜索条件
    resetSearchData () {
      // 机构
      this.departId = "";
      this.departName = "";
      // 车辆
      this.carType = ''
      this.carTypeName = ''
      this.carNumber = ''
      this.carNumberId = ''
      // 网格
      this.workLevel = ''
      this.workLevelName = ''
      this.gridName = ''
      this.gridId = ''
      this.planName = ''
      this.planId = []
      this.plan = []
      // 违规
      this.violateType = ''

      this.status = 0
      this.date = []
      this.buttonIndex = null
      // this.currentTabIndex = 0
      switch (this.currentTabIndex) {
        case 0:
          this.currentGridWorkTabIndex = 0
          break;
        default:
          break;
      }
    },
    // 日期回调
    onChangeDate (date) {
      if (this.selectType == 1) {
        this.date = date
      } else {
        let time = date.split('-')
        let day = new Date(time[0], time[1], 0).getDate()
        this.date[0] = date + '-01'
        this.date[1] = date + '-' + day
      }
      this.buttonIndex = null
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    onChangeSort (column, key, order) {

    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentGridWorkTabIndex) {
            case 0:
              this.getGridJobSummaryList()
              break;
            case 1:
              this.getGridJobDetailList()
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    },
    // 网格汇总列表
    getGridJobSummaryList () {
      let params = {
        depId: this.depId,
        page: this.pageNum,
        size: this.pageSize,
        startTime: this.date[0],
        endTime: this.date[1],
        empId: '',
        gridId: this.gridId,
        planId: this.depId,
        planName: this.planName

      }
      this.tableLoading = true
      this.$http.getGridJobSummaryList(params).then(res => {
        this.data = res.result.rows
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 网格明细列表
    getGridJobDetailList () {
      let params = {
        depId: this.depId,
        page: this.pageNum,
        size: this.pageSize,
        empId: this.empId,
        gridId: this.gridId,
        planId: this.depId,
        planName: this.planName,
        startTime: this.date[0],
        endTime: this.date[1],
      }
      this.tableLoading = true
      this.$http.getGridJobDetailList(params).then(res => {
        this.data = res.result.rows
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
.plan-name {
  ::v-deep .ivu-select-selection {
    max-height: 32px;
    overflow: hidden;
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow-y: auto;
  background-color: #f0f2f5;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
    }
  }
  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
    }
    .table {
      margin-top: 16px;
      flex: 1;
    }
    .noData {
      height: 368px;
      img {
        width: 172px;
        height: 100px;
      }
    }
  }
}
</style>